import { Col, Table } from "antd";
import React, { useContext } from "react";
import moment from "moment";
import { amountFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
import UserContext from "../../Context/UserContext";

const columns = [
	{
		title: "Description",
		dataIndex: "description",
		key: "description",
	},
	{
		title: "Amount",
		dataIndex: "amount",
		key: "amount",
		render: (text, record) => (
			<p style={styles.credit} data-testid="earnings-amount">
				{amountFormatter(text, record.type)}
			</p>
		),
	},
	{
		title: "Date",
		dataIndex: "created",
		key: "created",
		render: (text) => (
			<span data-testid="earnings-date">{moment(text).format("MMMM D YYYY @ h:mm a")}</span>
		),
	},
];

const EarningsTable = () => {
	const { transactions } = useContext(UserContext);

	return (
		<Col className="Card">
			<Table dataSource={transactions} columns={columns} style={{ overflow: "auto" }} />
		</Col>
	);
};

const styles = {
	credit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("green"),
	},
};

export default EarningsTable;
