import React, { useContext, useState } from "react";
import { Row, Col, Typography, Button, Popconfirm, message } from "antd";
import { Redirect } from "react-router-dom";
import firebase from "../../API/firebase";
import ViewContext from "../../Context/ViewContext";
const { Title } = Typography;

const PackageViewQuickActions = ({ data = {}, toggleEditing }) => {
	const { isMobile, isIpad } = useContext(ViewContext);
	const [loading, setloading] = useState(false);
	const [nav, setNav] = useState(false);
	const [link, setLink] = useState("");

	const deletePackage = async () => {
		setloading(true);
		await firebase
			.firestore()
			.collection("Coaches")
			.doc(data.owner)
			.collection("Packages")
			.doc(data.key)
			.delete()
			.then(() => {
				setloading(false);
				setLink(`/My-Packages`);
				setNav(true);
			})
			.catch(() => {
				setloading(false);
				message.error("Could not delete package");
			});
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row style={styles.row}>
				<Col style={{ padding: "0px 3px 6px" }} span={isMobile || isIpad ? 24 : 12}>
					<Button
						loading={loading}
						onClick={toggleEditing}
						style={styles.button}
						data-testid="edit"
					>
						Edit Package
					</Button>
				</Col>
				<Col style={{ padding: "0px 3px" }} span={isMobile || isIpad ? 24 : 12}>
					<Popconfirm
						okText="Yep"
						cancelText="Cancel"
						placement="topRight"
						title="Are you sure you want to delete this package?"
						onConfirm={deletePackage}
					>
						<Button loading={loading} danger style={styles.button} data-testid="delete">
							Delete Package
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			{nav && <Redirect to={link} />}
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
		height: "auto",
		whiteSpace: "normal",
	},
	row: {
		width: "100%",
	},
};

export default PackageViewQuickActions;
