import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

const ViewContext = React.createContext();

export const ViewProvider = ({ children }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const windowHeight = window.innerHeight;
	const windowWidth = window.innerWidth;

	let isMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 });
	let isIpad = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
	let isLaptop = useMediaQuery({ minWidth: 1200, maxWidth: 1399 });
	let isDesktop = useMediaQuery({ minWidth: 1400, maxWidth: 999999 });

	// TO DO: ADD SCREEN SIZE HANDLER FUNCTIONS HERE
	// useEffect(() => {
	// 	if (isMobile) {
	// 		message.info("is mobile");
	// 	} else if (isIpad) {
	// 		message.info("is ipad");
	// 	} else if (isLaptop) {
	// 		message.info("is laptop");
	// 	} else if (isDesktop) {
	// 		message.info("is desktop");
	// 	} else {
	// 		console.log("Unknown screen size");
	// 	}
	// }, [isMobile, isIpad, isLaptop, isDesktop]);

	return (
		<ViewContext.Provider
			value={{
				drawerOpen,
				setDrawerOpen,
				isCollapsed,
				setIsCollapsed,
				isMobile,
				isIpad,
				isLaptop,
				isDesktop,
				windowHeight,
				windowWidth,
			}}
		>
			{children}
		</ViewContext.Provider>
	);
};

export default ViewContext;
