import React, { useContext, useState } from "react";
import { Row, Col, Layout, Typography, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import ViewContext from "../../Context/ViewContext";
import AddPackage from "../../Components/MyPackages/AddPackage";
import PackagesTable from "../../Components/MyPackages/PackagesTable";

const { Header, Content } = Layout;

const Packages = () => {
	const { isMobile } = useContext(ViewContext);
	const [addModal, setAddModal] = useState(false);
	return (
		<Layout className="App">
			<MenuMain active={["2"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Row justify="center" style={{ width: "100%" }}>
						<Col span={24} style={{ maxWidth: maxContentWidth, width: "100%" }}>
							<Row justify="space-between">
								<Typography.Title>My Packages</Typography.Title>
								<Button
									onClick={() => setAddModal(!addModal)}
									type="primary"
									style={{ margin: "12px 0px" }}
									data-testid="package-button"
								>
									<PlusOutlined /> New Package
								</Button>
							</Row>
							<Col className="Card">
								<PackagesTable />
							</Col>
							{addModal && (
								<AddPackage visible={addModal} dismiss={() => setAddModal(false)} />
							)}
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default Packages;
