import React, { useState } from "react";
import { Button, Col, notification, Row, Typography } from "antd";
import firebase from "../../API/firebase";
const { Title } = Typography;

const AccountViewQuickActions = ({ data }) => {
	const [loading, setloading] = useState(false);

	const resetPassword = async () => {
		setloading(true);
		await firebase.auth().sendPasswordResetEmail(data.email);
		openNotificationWithIcon(
			"success",
			"Password successfully reset",
			`${data.firstName} has been sent a password reset email to ${data.email}`
		);
		setloading(false);
		return;
	};

	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
		});
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row style={styles.row}>
				<Col span={24}>
					<Button
						loading={loading}
						onClick={resetPassword}
						style={styles.button}
						data-testid="reset"
					>
						Reset Password
					</Button>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default AccountViewQuickActions;
