import React from "react";
import { Row, Col } from "antd";

const CoverModalLibrary = ({ data, changeImage }) => {
	return (
		<Col style={{ maxHeight: 550, overflow: "scroll" }} span={24}>
			<Row>
				<p>Click on image to update cover pic</p>
			</Row>
			<Row>
				{data.map((item, index) => (
					<Col key={index} span={8} data-testid="image-item">
						<img
							onClick={() => changeImage(item)}
							alt="coverphoto"
							src={item.url}
							style={{
								height: "100%",
								width: "100%",
								objectFit: "cover",
								cursor: "pointer",
							}}
							data-testid="image"
						/>
					</Col>
				))}
			</Row>
		</Col>
	);
};

export default CoverModalLibrary;
