import React, { useState } from "react";
import { Button, Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import AvailabilityModal from "./AvailabilityModal";

const AvailabilityTable = ({ data = {}, setUserInfo }) => {
	const [modal, setModal] = useState(false);

	const toggleAvailModal = () => setModal(!modal);

	return (
		<Col className="Card">
			<Row style={{ marginBottom: 6 }} justify="space-between">
				<Typography.Title level={5}>Booking Availability:</Typography.Title>
				<Button size="small" onClick={toggleAvailModal} data-testid="edit">
					Edit
				</Button>
			</Row>
			<Divider style={{ margin: 0, marginBottom: 6 }} />
			<Row justify="space-between">
				<Col span={8}></Col>
				<Col span={8}>
					<p className="Key">Start</p>
				</Col>
				<Col span={8}>
					<p className="Key">Finish</p>
				</Col>
			</Row>
			{data.availability &&
				data.availability.map((item, index) => (
					<Row key={index} justify="space-between">
						<Col span={8}>
							<p className="Key">{item.day}</p>
						</Col>
						<Col span={8} data-testid="start">
							<p>{item.active ? moment(item.start, "HHmm").format("h:mm a") : "-"}</p>
						</Col>
						<Col span={8} data-testid="finish">
							<p>
								{item.active ? moment(item.finish, "HHmm").format("h:mm a") : "-"}
							</p>
						</Col>
					</Row>
				))}
			{modal && (
				<AvailabilityModal
					visible={modal}
					defs={data.availability}
					dismiss={toggleAvailModal}
					userInfo={data}
					setUserInfo={setUserInfo}
				/>
			)}
		</Col>
	);
};

export default AvailabilityTable;
