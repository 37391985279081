import React, { useState } from "react";
import { Row } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { colorsLight } from "../../Styles/Themes";

const BackButton = ({ to, history }) => {
	const [nav, setNav] = useState(false);

	const handleNav = () => {
		if (history) {
			history.goBack();
		} else {
			setNav(true);
		}
	};

	return (
		<Row
			onClick={handleNav}
			justify="center"
			align="middle"
			style={styles.wrapper}
			data-testid="back-btn"
		>
			<LeftCircleOutlined style={styles.icon} />
			<p style={styles.font}>Back</p>
			{nav && <Redirect to={to} />}
		</Row>
	);
};

const styles = {
	icon: {
		color: colorsLight("darkGrey"),
		fontSize: 19,
		marginLeft: 12,
		marginRight: 6,
	},
	font: {
		color: colorsLight("darkGrey"),
		fontSize: 20,
		marginTop: 0,
		marginBottom: 0,
	},
	wrapper: {
		cursor: "pointer",
	},
};

export default BackButton;
