import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { PieChartOutlined, CalendarOutlined, CrownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo-small.png";
import LogoSmall from "../../Assets/logoInitials.png";
import { colorsLight } from "../../Styles/Themes";

const { Sider } = Layout;

const MenuMain = ({ active }) => {
	const [collapsed, setCollapsed] = useState(false);

	return (
		<Sider
			collapsible
			collapsed={collapsed}
			onCollapse={() => setCollapsed(!collapsed)}
			width={200}
			className="site-layout-background"
			data-testid="menu-desktop"
		>
			<div
				style={{
					backgroundColor: colorsLight("primary"),
					height: 64,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				<img
					src={collapsed ? LogoSmall : Logo}
					alt="Careerbase logo"
					style={{ height: 22, alignSelf: "center" }}
				/>
			</div>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys="1"
				selectedKeys={active}
				style={{ height: "100%" }}
			>
				<Menu.Item key="1" title="Home" icon={<PieChartOutlined />}>
					<Link to="/Home" data-testid="menu-home">
						Home
					</Link>
				</Menu.Item>
				<Menu.Item key="2" title="My Packages" icon={<CrownOutlined />}>
					<Link to="/My-Packages" data-testid="menu-packages">
						My Packages
					</Link>
				</Menu.Item>
				{/* <Menu.Item key="3" title="My Schedule" icon={<CalendarOutlined />}>
					<Link to="/My-Schedule" data-testid="menu-schedule">
						My Schedule
					</Link>
				</Menu.Item> */}
				{/* <Menu.Item key="4" title="My Profile" icon={<CrownOutlined />}>
					<Link to="/My-Account" data-testid="menu-packages">
						My Profile
					</Link>
				</Menu.Item> */}
			</Menu>
		</Sider>
	);
};

export default MenuMain;
