import React from "react";
import { Col, Row } from "antd";
import moment from "moment";

const ScheduleItem = ({ data }) => {
	return (
		<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={24}>
			<Row>
				<Col>
					<span
						style={{
							color: "green",
							fontSize: 17,
							fontWeight: 600,
						}}
						data-testid="schedule-time"
					>
						{moment(data.startTime).format("h:mm a")} -{" "}
						{moment(data.endTime).format("h:mm a")}
					</span>
					<span
						style={{
							margin: "0px 10px",
							fontWeight: 400,
							fontSize: 16,
						}}
						data-testid="schedule-title"
					>
						{data.title}
					</span>
				</Col>
			</Row>
		</Col>
	);
};

export default ScheduleItem;
