import React, { useContext } from "react";
import { Avatar, Dropdown, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UserContext from "../../Context/UserContext";
import { getInitials } from "../../Functions/formatters";
import MyAccountDropdown from "./AccountSettingsDropdown";

const MyAccountIcons = () => {
	const { userInfo } = useContext(UserContext);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	return (
		<Dropdown
			overlay={<MyAccountDropdown />}
			placement="bottomRight"
			trigger={["click"]}
			data-testid="menu-account"
		>
			<Avatar
				src={userInfo.displayPic}
				style={{ cursor: "pointer" }}
				size={50}
				data-testid="menu-avatar"
			>
				{userInfo.firstName !== " " ? (
					getInitials(`${userInfo.firstName} ${userInfo.lastName}`)
				) : (
					<div data-testid="menu-spinner">
						<Spin indicator={antIcon} />
					</div>
				)}
			</Avatar>
		</Dropdown>
	);
};

export default MyAccountIcons;
