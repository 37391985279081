export const coachCategories = [
	"Executive",
	"Career",
	"Job Search",
	"Wellness",
	"Fitness",
	"Parental",
	"Meditation",
	"Negotiation",
	"Public Relations",
	"Branding",
	"Transition",
	"Outplacement",
	"Finance",
];
