import { Button, Col, Modal, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useContext, useState } from "react";
import UserContext from "../../Context/UserContext";

const OnboardModal = ({ visible, dismiss }) => {
	const [step, setStep] = useState(0);
	const { userInfo } = useContext(UserContext);

	const nextStep = async () => {
		if (step < 3) {
			console.log(step);
			setStep(step + 1);
		} else {
			dismiss();
			await firestore()
				.collection("Coaches")
				.doc(userInfo.key)
				.update({ tutorialComplete: true });
		}
	};

	const close = () => {
		firestore()
			.collection("Coaches")
			.doc(userInfo.key)
			.update({ tutorialComplete: true })
			.then(() => {
				dismiss();
			})
			.catch((err) => {
				console.log(err);
				dismiss();
			});
	};

	const availTableImage =
		"https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2FavailabilityTable.png?alt=media&token=b570f938-4fb4-46f5-af89-62f43bf172ea";
	const calIntegrationImage =
		"https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2FcalendarIntegration.png?alt=media&token=161bf058-6eb3-435a-be0d-a75482a344e2";

	return (
		<Modal
			visible={visible}
			onCancel={close}
			footer={[
				<Button onClick={close} key="skip">
					Skip
				</Button>,
				<Button onClick={nextStep} key="next">
					{step === 3 ? "Get started" : "Next"}
				</Button>,
			]}
		>
			{step === 0 && (
				<Col span={24}>
					<Row>
						<Typography.Title level={3}>Welcome to Careerbase!</Typography.Title>
					</Row>
					<Row>
						<Typography.Title level={5}>
							Let's get your account set up.
						</Typography.Title>
					</Row>
					<Row style={{ width: "90%" }}>
						<p>
							First, please check the details in your coaching profile. This is the
							infomation potential clients will see when checking for a good fit, so
							make sure it's up to your standards.
						</p>
					</Row>
					<Row style={{ width: "90%" }}>
						<p>
							Just click on the "Edit Details" button and be sure to click "Update"
							once done.
						</p>
					</Row>
				</Col>
			)}
			{step === 1 && (
				<Col span={24}>
					<Row>
						<Typography.Title level={4}>Update your availability.</Typography.Title>
					</Row>
					<Row style={{ width: "90%" }}>
						<p>This will set your bookable hours for clients on the platform.</p>
					</Row>
					<Row justify="center">
						<img src={availTableImage} style={{ width: "60%" }} />
					</Row>
				</Col>
			)}
			{step === 2 && (
				<Col span={24}>
					<Row>
						<Typography.Title level={4}>Integrate your calendar.</Typography.Title>
					</Row>
					<Row style={{ width: "90%" }}>
						<p>This will make sure we've got your availability for future bookings.</p>
					</Row>
					<Row justify="center">
						<img src={calIntegrationImage} style={{ width: "90%" }} />
					</Row>
				</Col>
			)}
			{step === 3 && (
				<Col span={24}>
					<Row>
						<Typography.Title level={4}>We're here if you need help</Typography.Title>
					</Row>
					<Row style={{ width: "90%" }}>
						<p>
							Please reach out to us via the Slack channel or via email should you
							need any assistance.
						</p>
					</Row>
				</Col>
			)}
		</Modal>
	);
};

export default OnboardModal;
