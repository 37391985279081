import React, { useState, useContext } from "react";
import { Button, Col, Drawer, Form, Input, InputNumber, notification, Row, Select } from "antd";
import moment from "moment";
import ReactQuill from "react-quill";
import firebase from "../../API/firebase";
import "../../Styles/quill.snow.css";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";

const { Option } = Select;

const AddPackage = ({ dismiss, visible }) => {
	const { isMobile } = useContext(ViewContext);
	const { userInfo } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState("");

	const now = new Date();

	const openNotificationWithIcon = (type, error) => {
		notification[type]({
			message: "Something has gone wrong.",
			description: error,
		});
	};

	const createPackage = (val) => {
		setLoading(true);
		const obj = {
			name: val.name,
			sessionFrequency: val.sessionFrequency,
			sessions: val.sessions,
			duration: val.duration,
			created: moment(now).format(),
			owner: userInfo.key,
			costPrice: val.costPrice,
			summary: val.summary,
			details: description,
			coverPic: "",
			displayPic: userInfo.displayPic,
			type: "Coaching",
			delivery: val.delivery,
			primaryCategory: userInfo.primaryCategory,
			updated: moment(now).format() || "missing data",
			orders: 0,
			coachName: userInfo.displayName,
		};

		firebase
			.firestore()
			.collection("Coaches")
			.doc(userInfo.key)
			.collection("Packages")
			.add(obj)
			.then(() => {
				setLoading(false);
				dismiss();
			})
			.catch((error) => {
				openNotificationWithIcon("error", error);
			});
	};

	const handleCloseModal = () => {
		dismiss();
		setDescription("");
	};

	return (
		<Drawer
			destroyOnClose={true}
			title="Add New Package"
			width={isMobile ? "100%" : 720}
			onClose={handleCloseModal}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
			footer={
				<div
					style={{
						textAlign: "right",
					}}
				>
					<Button
						onClick={handleCloseModal}
						style={{ marginRight: 8 }}
						data-testid="cancel"
					>
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						data-testid="create"
					>
						Create
					</Button>
				</div>
			}
		>
			<Form id="myForm" layout="vertical" hideRequiredMark onFinish={createPackage}>
				<Row gutter={16}>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							name="name"
							label="Package name:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter package name",
								},
							]}
						>
							<Input
								allowClear={true}
								placeholder="Please enter package name"
								data-testid="name"
							/>
						</Form.Item>
					</Col>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							name="delivery"
							label="Package Delivery:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please selection package delivery",
								},
							]}
						>
							<Select
								allowClear={true}
								placeholder="Please select package delivery"
								data-testid="delivery"
							>
								<Option key="1" value="In-Person">
									In-person
								</Option>
								<Option key="2" value="Zoom">
									Zoom
								</Option>
								<Option key="3" value="Zoom or In-person">
									Zoom or In-person
								</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={isMobile ? 24 : 8}>
						<Form.Item
							label={`Number of sessions:`}
							style={{
								width: "100%",
							}}
							name="sessions"
							rules={[
								{
									required: true,
									message: "Please select number of sessions",
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={20}
								placeholder="Please select number of sessions"
								data-testid="sessions"
							/>
						</Form.Item>
					</Col>
					<Col span={isMobile ? 24 : 8}>
						<Form.Item
							label={`Duration:`}
							style={{
								width: "100%",
							}}
							name="duration"
							rules={[
								{
									required: true,
									message: "Please select session duration",
								},
							]}
						>
							<Select
								placeholder="Please choose session duration"
								data-testid="duration"
							>
								<Option value={15}>15 mins</Option>
								<Option value={30}>30 mins</Option>
								<Option value={45}>45 mins</Option>
								<Option value={60}>60 mins</Option>
								<Option value={90}>90 mins</Option>
								<Option value={120}>120 mins</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={isMobile ? 24 : 8}>
						<Form.Item
							name="sessionFrequency"
							label="Session Frequency:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter session frequency",
								},
							]}
						>
							<Select
								allowClear={true}
								placeholder="Please select session frequency"
								data-testid="frequency"
							>
								<Option value="Once">Once</Option>
								<Option value="Daily">Daily</Option>
								<Option value="Weekly">Weekly</Option>
								<Option value="Fortnightly">Fortnightly</Option>
								<Option value="Monthly">Monthly</Option>
								<Option value="Quarterly">Quarterly</Option>
								<Option value="Yearly">Yearly</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							name="costPrice"
							label="Your Earnings:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter package pricing",
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								allowClear={true}
								placeholder="Please enter package pricing"
								formatter={(value) =>
									`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
								data-testid="earnings"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="summary"
							label="Package Summary:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter package summary",
								},
							]}
						>
							<Input.TextArea data-testid="summary" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<p>Package Details:</p>
						<ReactQuill
							value={description}
							onChange={setDescription}
							placeholder="Pleast enter details"
							theme="snow"
							style={{ width: "100%" }}
							data-testid="details"
						/>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
};

export default AddPackage;
