import React, { useContext } from "react";
import { Row, Col, Layout, Typography } from "antd";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import ViewContext from "../../Context/ViewContext";
import Calendar from "../../Components/MySchedule/Calendar";
const { Header, Content } = Layout;

const MySchedulePage = () => {
	const { isMobile } = useContext(ViewContext);
	return (
		<Layout className="App">
			<MenuMain active={["3"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Row justify="center">
						<Col
							span={24}
							style={{
								backgroundColor: colorsLight("lightgrey"),
								height: "100%",
								maxWidth: maxContentWidth,
							}}
						>
							<Typography.Title>My Schedule</Typography.Title>
							<Calendar />
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default MySchedulePage;
