import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Upload, Progress, message } from "antd";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { colorsLight } from "../../Styles/Themes";
import { storage, firestore } from "firebase";

const ProfilePicUploadModal = ({ visible, dismiss, data, setUserInfo }) => {
	const [loading, setLoading] = useState(false);
	const [progress, setprogress] = useState(0);

	const [photo, setPhoto] = useState("");
	const [fileRef, setFileRef] = useState("");

	const [changed, setChanged] = useState(false);

	const uploadFile = async ({ onError, onSuccess, file }) => {
		setLoading(true);
		const reff = `displayPics/${data.key}/${file.name}`;
		setFileRef(reff);
		let uploadTask = await storage().ref().child(reff).put(file);
		uploadTask.task.on(
			"state_changed",
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setprogress(Number(progress.toFixed(0)));
			},
			(err) => {
				onError();
				setLoading(false);
				setprogress(0);
				message.error("Something went wrong");
				console.log(err);
			},
			() => {
				uploadTask.task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
					onSuccess();
					setPhoto(downloadUrl);
					setLoading(false);
					setprogress(0);
					setChanged(true);
					return;
				});
			}
		);
	};

	useEffect(() => {
		if (data.displayPic) {
			setPhoto(data.displayPic);
			setFileRef(data.dpFileRef || "");
		}
	}, [data, visible]);

	const deleteImage = async () => {
		if (changed && fileRef) {
			await storage().ref().child(fileRef).delete();
			setPhoto("");
			setFileRef("");
		} else {
			setPhoto("");
			setFileRef("");
			setChanged(true);
		}
	};

	const DeleteButton = () => {
		return (
			<Row
				onClick={deleteImage}
				justify="center"
				align="middle"
				style={styles.delWrapper}
				data-testid="delete"
			>
				<DeleteOutlined />
			</Row>
		);
	};

	const closeModal = async () => {
		if (data.displayPic !== photo) {
			if (photo && fileRef) {
				try {
					await storage().ref().child(fileRef).delete();
					dismiss();
					setChanged(false);
					setPhoto("");
					setFileRef("");
				} catch (err) {
					setPhoto("");
					setFileRef("");
					dismiss();
					setChanged(false);
				}
			} else {
				dismiss();
				setChanged(false);
			}
		} else {
			dismiss();
			setChanged(false);
		}
	};

	const saveChanges = async () => {
		if (data.displayPic !== photo && data.dpFileRef !== fileRef) {
			data.dpFileRef && (await storage().ref().child(data.dpFileRef).delete());
			await firestore()
				.collection("Coaches")
				.doc(data.key)
				.update({ displayPic: photo, dpFileRef: fileRef })
				.then(() => {
					setUserInfo({ ...data, displayPic: photo, dpFileRef: fileRef });
					setPhoto("");
					setFileRef("");
					dismiss();
					setChanged(false);
				})
				.catch((e) => console.log(e, "error"));
		} else {
			setPhoto("");
			setFileRef("");
			dismiss();
			setChanged(false);
		}
	};

	return (
		<Modal
			visible={visible}
			onCancel={closeModal}
			title="Edit Profile Picture"
			footer={
				<Row justify="end">
					<Button onClick={closeModal} data-testid="cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						disabled={!changed}
						onClick={saveChanges}
						style={{ marginLeft: 12 }}
						data-testid="done"
					>
						Done
					</Button>
				</Row>
			}
		>
			<Col span={24}>
				<Row justify="center">
					<Row style={styles.imageWrapper}>
						{photo && (
							<img
								src={photo}
								style={{ height: 300, width: 300, objectFit: "cover" }}
								alt="coverphoto"
							/>
						)}
						{!photo && !loading && (
							<Upload
								customRequest={uploadFile}
								onRemove={deleteImage}
								showUploadList={false}
								data-testid="upload"
							>
								<Row
									justify="center"
									align="middle"
									style={{
										height: 300,
										width: 300,
										backgroundColor: colorsLight("grey"),
									}}
								>
									<Col>
										<Row justify="center" align="middle">
											<UserOutlined style={styles.icon} />
										</Row>
										<Row justify="center" align="middle">
											<p
												style={{
													fontSize: 18,
													textAlign: "center",
													color: colorsLight("darkGrey"),
												}}
											>
												Click or drag files to upload
											</p>
										</Row>
									</Col>
								</Row>
							</Upload>
						)}
						{loading && (
							<Row
								justify="center"
								align="middle"
								style={{
									height: 300,
									width: 300,
									backgroundColor: colorsLight("grey"),
								}}
							>
								<Col>
									<Row justify="center" align="middle">
										<Progress type="circle" percent={progress} width={80} />
									</Row>
									<Row justify="center" align="middle">
										<p
											style={{
												fontSize: 18,
												textAlign: "center",
												color: colorsLight("darkGrey"),
											}}
										>
											Click or drag files to upload
										</p>
									</Row>
								</Col>
							</Row>
						)}
						{photo && <DeleteButton />}
					</Row>
				</Row>
			</Col>
		</Modal>
	);
};

const styles = {
	imageWrapper: {
		padding: 12,
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		backgroundColor: "white",
	},
	delWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		cursor: "pointer",
		position: "absolute",
		top: 20,
		right: 94,
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
	},
	icon: {
		fontSize: 42,
		color: colorsLight("darkGrey"),
		marginBottom: 12,
	},
};

export default ProfilePicUploadModal;
