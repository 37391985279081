import React, { useState } from "react";
import { Badge, Button, Col, Input, Divider, InputNumber, Row, Select, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { getImage } from "../../Functions/utilities";
import "../../Styles/quill.snow.css";
import { colorsLight } from "../../Styles/Themes";
import CoverPhotoUploadModal from "../Common/CoverPhotoUploadModal";
import { getSalePrice } from "../../Functions/helpers";
import { priceFormatter } from "../../Functions/formatters";

const { Title } = Typography;
const { TextArea } = Input;
const PackageViewHeader = ({
	data,
	editing,
	editData,
	updateEditData,
	updatePackage,
	toggleEditing,
}) => {
	const [editCover, setEditCover] = useState(false);

	const toggleEdit = () => setEditCover(!editCover);
	return (
		<Badge.Ribbon
			text={data.status ? "ACTIVE" : "INACTIVE"}
			placement="start"
			color={data.status ? colorsLight("green") : colorsLight("darkRed")}
		>
			<Col className="Card" style={{ padding: 0, paddingBottom: 24 }}>
				<Row
					style={{
						backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						height: 120,
						width: "100%",
						borderTopRightRadius: 9,
						borderTopLeftRadius: 9,
					}}
				>
					<Row
						justify="end"
						style={{
							backgroundColor: colorsLight("cardCover"),
							height: "100%",
							width: "100%",
							borderTopRightRadius: 9,
							borderTopLeftRadius: 9,
						}}
					>
						<Row
							onClick={() => setEditCover(true)}
							justify="center"
							align="middle"
							style={styles.editIconWrapper}
							data-testid="edit-icon"
						>
							<EditOutlined style={styles.editIcon} />
						</Row>
					</Row>
				</Row>
				<div style={{ padding: 12 }}>
					{editing && (
						<Row justify="end">
							<Button onClick={toggleEditing} data-testid="cancel">
								Cancel
							</Button>
							<Button
								onClick={updatePackage}
								style={styles.button}
								type="primary"
								data-testid="edit"
							>
								Save
							</Button>
						</Row>
					)}
					<Row style={{ width: "100%" }}>
						{!editing ? (
							<Title level={2} data-testid="name">
								{data.name}
							</Title>
						) : (
							<input
								style={styles.input}
								value={editData.name}
								onChange={(val) => updateEditData("name", val.target.value)}
								data-testid="name"
							/>
						)}
					</Row>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
					<Row>
						<Col sm={6} xs={12} md={12} lg={6}>
							<Row justify="space-around">
								<Col>
									<Title level={5} style={styles.label}>
										Sessions:
									</Title>
									{!editing ? (
										<p style={styles.value}>{data.sessions}</p>
									) : (
										<InputNumber
											style={{
												fontSize: 18,
												color: colorsLight("lightBlue"),
												marginTop: 10,
												fontWeight: "bold",
												padding: 3,
											}}
											min={0}
											max={20}
											value={editData.sessions}
											onChange={(val) => updateEditData("sessions", val)}
											data-testid="sessions"
										/>
									)}
								</Col>
							</Row>
						</Col>
						<Col sm={6} xs={12} md={12} lg={6}>
							<Row justify="space-around">
								<Col>
									<Title level={5} style={styles.label}>
										Length:
									</Title>
									{!editing ? (
										<p style={styles.value} data-testid="length">
											{data.duration} mins
										</p>
									) : (
										<Select
											style={{
												fontSize: 18,
												color: colorsLight("lightBlue"),
												marginTop: 10,
												fontWeight: "bold",
												padding: 3,
											}}
											value={editData.duration}
											onChange={(val) => updateEditData("duration", val)}
											data-testid="length"
										>
											<Select.Option value={15}>15 mins</Select.Option>
											<Select.Option value={30}>30 mins</Select.Option>
											<Select.Option value={45}>45 mins</Select.Option>
											<Select.Option value={60}>60 mins</Select.Option>
											<Select.Option value={90}>90 mins</Select.Option>
											<Select.Option value={120}>120 mins</Select.Option>
										</Select>
									)}
								</Col>
							</Row>
						</Col>
						<Col sm={6} xs={12} md={12} lg={6}>
							<Row justify="space-around">
								<Col>
									<Title level={5} style={styles.label}>
										Frequency:
									</Title>
									{!editing ? (
										<p
											style={{ ...styles.value, fontSize: 28, marginTop: 3 }}
											data-testid="frequency"
										>
											{data.sessionFrequency}
										</p>
									) : (
										<Select
											style={{
												fontSize: 18,
												color: colorsLight("lightBlue"),
												marginTop: 10,
												fontWeight: "bold",
												padding: 3,
											}}
											value={editData.sessionFrequency}
											onChange={(val) =>
												updateEditData("sessionFrequency", val)
											}
											data-testid="frequency"
										>
											<Select.Option value="Once">Once</Select.Option>
											<Select.Option value="Daily">Daily</Select.Option>
											<Select.Option value="Weekly">Weekly</Select.Option>
											<Select.Option value="Fortnightly">
												Fortnightly
											</Select.Option>
											<Select.Option value="Monthly">Monthly</Select.Option>
											<Select.Option value="Quaterly">Quaterly</Select.Option>
										</Select>
									)}
								</Col>
							</Row>
						</Col>
						<Col sm={6} xs={12} md={12} lg={6}>
							<Row justify="center">
								<Col>
									<Title level={5} style={styles.label}>
										Your Fee:
									</Title>
									{!editing ? (
										<p
											style={{ ...styles.value, fontSize: 28, marginTop: 3 }}
											data-testid="price"
										>
											{priceFormatter(data.costPrice, "dollars")}
										</p>
									) : (
										<InputNumber
											formatter={(value) =>
												`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
											parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
											value={editData.costPrice}
											onChange={(val) => updateEditData("costPrice", val)}
											style={{
												...styles.value,
												fontSize: 18,
												marginTop: 10,
												padding: 3,
												width: 130,
											}}
										/>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
					<Title level={4}>Summary:</Title>
					{!editing ? (
						<Row style={{ paddingLeft: 6, paddingRight: 6, width: "100%" }}>
							<p data-testid="summary">{data.summary}</p>
						</Row>
					) : (
						<Col style={{ paddingLeft: 6, paddingRight: 6 }} span={24}>
							<TextArea
								style={{ marginBottom: 12, width: "100%" }}
								value={editData.summary}
								onChange={(val) => updateEditData("summary", val.target.value)}
								data-testid="summary"
							/>
						</Col>
					)}

					{(editing || (data.details !== "<p><br></p>" && data.details !== "")) && (
						<Title style={{ width: "100%" }} level={4}>
							Details:
						</Title>
					)}
					<Row style={{ paddingLeft: 6, paddingRight: 6, width: "100%" }}>
						{!editing ? (
							<div
								dangerouslySetInnerHTML={{ __html: data.details }}
								data-testid="bio"
							/>
						) : (
							<ReactQuill
								placeholder="Enter mentor bio..."
								theme="snow"
								value={editData.details}
								onChange={(val) => updateEditData("details", val)}
								style={{ width: "100%", marginBottom: 24 }}
								data-testid="bio"
							/>
						)}
					</Row>
					<CoverPhotoUploadModal
						visible={editCover}
						dismiss={toggleEdit}
						userType="CoachPackages"
						data={data}
						packLevel={true}
					/>
				</div>
			</Col>
		</Badge.Ribbon>
	);
};

const styles = {
	input: {
		fontSize: 30,
		fontWeight: 600,
		outline: "none",
		margin: 0,
		width: "100%",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#CCCCCC",
		marginTop: 12,
	},
	button: {
		marginLeft: 12,
	},
	label: {
		textAlign: "center",
		margin: 0,
	},
	value: {
		fontSize: 32,
		color: colorsLight("lightBlue"),
		margin: 0,
		textAlign: "center",
		fontWeight: "bold",
	},

	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
};

export default PackageViewHeader;
