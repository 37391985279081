import React, { useEffect, useState, useContext } from "react";
import { Col, Layout, Row } from "antd";
import UserContext from "../../Context/UserContext";
import firebase from "../../API/firebase";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight } from "../../Styles/Themes";
import AvailabilityTable from "../../Components/MyAccount/AvailabilityTable";
import AccountViewHeader from "../../Components/MyAccount/AccountViewHeader";
import AccountViewQuickActions from "../../Components/MyAccount/AccountViewQuickActions";
import ViewContext from "../../Context/ViewContext";
import CalendarSync from "../../Components/MyAccount/CalendarSync";
import { firestore } from "firebase";
import OnboardModal from "../../Components/Onboarding/OnboardModal";

const { Header, Content } = Layout;

const CoachViewPage = () => {
	const { uid, userInfo, setUserInfo, authd } = useContext(UserContext);
	const { isMobile } = useContext(ViewContext);
	const [privateData, setPrivateData] = useState({});
	const [editing, setEditing] = useState(false);
	const [onboardingModal, setOnboardingModal] = useState(false);

	const toggleEdit = () => setEditing(!editing);
	const toggleModal = () => setOnboardingModal(!onboardingModal);

	useEffect(() => {
		if (authd) {
			console.log(process.env.REACT_APP_PROJECT_ID);
			let privateData = firestore()
				.collection("UserRecords")
				.doc(uid)
				.onSnapshot((doc) => {
					setPrivateData({ ...doc.data(), key: doc.id });
				});

			return () => {
				privateData();
			};
		}
	}, [uid]);

	useEffect(() => {
		if (userInfo.status) {
			!userInfo.tutorialComplete && setOnboardingModal(true);
		}
	}, [userInfo]);

	return (
		<Layout className="App">
			<MenuMain active={["1"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Col style={styles.main}>
						{!isMobile ? (
							<Row style={styles.row}>
								<Col span={16}>
									<AccountViewHeader
										editing={editing}
										toggleEdit={toggleEdit}
										data={userInfo}
										privateData={privateData}
										setPrivateData={setPrivateData}
										setUserInfo={setUserInfo}
									/>
									<CalendarSync />
								</Col>
								<Col style={{ paddingLeft: 24 }} span={8}>
									<AccountViewQuickActions
										toggleEdit={toggleEdit}
										data={userInfo}
										privateData={privateData}
									/>
									<AvailabilityTable data={userInfo} setUserInfo={setUserInfo} />
								</Col>
							</Row>
						) : (
							<Row style={styles.row}>
								<Col span={24}>
									<AccountViewQuickActions
										toggleEdit={toggleEdit}
										data={userInfo}
										privateData={privateData}
									/>
									<AccountViewHeader
										editing={editing}
										toggleEdit={toggleEdit}
										data={userInfo}
										privateData={privateData}
										setPrivateData={setPrivateData}
										setUserInfo={setUserInfo}
									/>
									<CalendarSync />
									<AvailabilityTable data={userInfo} setUserInfo={setUserInfo} />
								</Col>
							</Row>
						)}
					</Col>
					<OnboardModal visible={onboardingModal} dismiss={toggleModal} />
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default CoachViewPage;
