import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Layout, message } from "antd";
import { useParams } from "react-router-dom";
import BackButton from "../../Components/Navigation/BackButton";
import firebase from "../../API/firebase";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight } from "../../Styles/Themes";
import CoachPackageViewHeader from "../../Components/ViewPackage/PackageViewHeader";
import CoachPackageViewQuickActions from "../../Components/ViewPackage/PackageViewQuickActions";
import CoachPackageViewCommercials from "../../Components/ViewPackage/PackageViewCommercials";
import CoachPackageDetails from "../../Components/ViewPackage/PackageDetails";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
const { Header, Content } = Layout;

const CoachPackageView = () => {
	const { isMobile } = useContext(ViewContext);
	const { uid, userInfo } = useContext(UserContext);
	const [data, setData] = useState({});
	const { packageId } = useParams();
	const [editing, setEditing] = useState(false);
	const [originalRecord] = useState({});
	const [, setLoading] = useState(true);

	//EDITING STATE
	const [editData, setEditData] = useState({
		name: "",
		details: "",
		sessions: 0,
		duration: 0,
		sessionFrequency: "",
		summary: "",
		delivery: "",
		costPrice: 0,
	});

	const toggleEditing = () => setEditing(!editing);

	const handleEditData = (params, values) => {
		if (typeof params === "string") {
			setEditData({ ...editData, [params]: values });
		} else {
			const newData = {};
			params.forEach((param) => {
				newData[param] = values[param];
			});
			setEditData({ ...editData, ...newData });
		}
	};

	useEffect(() => {
		firebase
			.firestore()
			.collection("Coaches")
			.doc(uid)
			.collection("Packages")
			.doc(packageId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageId]);

	useEffect(() => {
		handleEditData(
			[
				"name",
				"details",
				"summary",
				"sessions",
				"duration",
				"sessionFrequency",
				"delivery",
				"costPrice",
			],
			data
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const updatePackage = () => {
		const payload = {
			...editData,
			displayPic: userInfo.displayPic,
			primaryCategory: userInfo.primaryCategory,
			coachName: userInfo.displayName,
		};

		firebase
			.firestore()
			.collection("Coaches")
			.doc(uid)
			.collection("Packages")
			.doc(packageId)
			.update(payload)
			.then(() => {
				toggleEditing();
			})
			.catch(() => {
				message.error("Could not update package");
			});
	};

	return (
		<Layout className="App">
			<MenuMain active={["4"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row
							className="Fill"
							justify="space-between"
							align="middle"
							style={styles.topRow}
						>
							<BackButton to={`/My-Packages`} />
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Col style={styles.main}>
						{isMobile ? (
							<>
								<Row justify="start" style={styles.back}>
									<BackButton to={`/My-Packages`} />
								</Row>
								<Row justify="center">
									<Col span={24}>
										<CoachPackageViewQuickActions
											toggleEditing={toggleEditing}
											data={data}
										/>
										<CoachPackageViewHeader
											editData={editData}
											updateEditData={handleEditData}
											editing={editing}
											data={data}
											updatePackage={updatePackage}
											toggleEditing={toggleEditing}
										/>

										<CoachPackageDetails
											editing={editing}
											data={data}
											delivery={editData.delivery}
											setDelivery={handleEditData}
										/>
									</Col>
								</Row>
							</>
						) : (
							<Row justify="center">
								<Col span={16}>
									<CoachPackageViewHeader
										editData={editData}
										updateEditData={handleEditData}
										editing={editing}
										data={data}
										updatePackage={updatePackage}
										toggleEditing={toggleEditing}
									/>
								</Col>
								<Col style={{ paddingLeft: 24 }} span={8}>
									<CoachPackageViewQuickActions
										toggleEditing={toggleEditing}
										data={data}
									/>
									<CoachPackageDetails
										editing={editing}
										data={data}
										delivery={editData.delivery}
										setDelivery={handleEditData}
									/>
								</Col>
							</Row>
						)}
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	back: {
		marginBottom: 10,
	},
};

export default CoachPackageView;
