import React, { useContext } from "react";
import { Row, Col, Layout, Typography } from "antd";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import UpcomingSchedule from "../../Components/Home/UpcomingSchedule";
import MyEarnings from "../../Components/Home/MyEarnings";
import ViewContext from "../../Context/ViewContext";
import UserContext from "../../Context/UserContext";
const { Header, Content } = Layout;

const Home = () => {
	const { isMobile } = useContext(ViewContext);
	const { userInfo } = useContext(UserContext);
	return (
		<Layout className="App">
			<MenuMain active={["1"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Row style={{ width: "100%" }} justify="center">
						<Col
							span={24}
							style={{
								backgroundColor: colorsLight("lightgrey"),
								height: "100%",
								maxWidth: maxContentWidth,
							}}
						>
							{userInfo.firstName && (
								<Typography.Title level={1} data-testid="home-user">
									Welcome, {userInfo.firstName}!
								</Typography.Title>
							)}

							<Row style={{ marginTop: 0, width: "100%" }}>
								<Col
									style={{
										padding: isMobile ? "0px 0px 12px" : "0px 12px 0px 0px",
									}}
									span={isMobile ? 24 : 12}
								>
									<UpcomingSchedule />
								</Col>
								<Col
									style={{ padding: isMobile ? 0 : "0px 0px 0px 12px" }}
									span={isMobile ? 24 : 12}
								>
									<MyEarnings />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default Home;
