import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Avatar,
	Divider,
	Typography,
	Form,
	Input,
	Button,
	Tabs,
	Select,
	Badge,
	message,
} from "antd";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { colorsLight } from "../../Styles/Themes";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import ProfilePicUploadModal from "../Common/ProfilePicUploadModal";
import CoverPhotoUploadModal from "../Common/CoverPhotoUploadModal";
import firebase from "../../API/firebase";
import AccountViewHeaderData from "./AccountViewHeaderData";
import { coachCategories } from "../../Functions/categories";
import { firestore } from "firebase";

const { Title } = Typography;
const { TextArea } = Input;

const AccountViewHeader = ({
	data,
	privateData,
	toggleEdit,
	editing,
	setUserInfo,
	setPrivateData,
}) => {
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	const [loading, setloading] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	const [editCover, setEditCover] = useState(false);
	const [summary, setSummary] = useState("");
	const [details, setDetails] = useState("");
	const [title, setTitle] = useState("");

	const imageBackground = {
		backgroundImage: `url(${data.coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	useEffect(() => {
		setDetails(data.details);
		setSummary(data.summary);
		setTitle(data.title);
	}, [data]);

	const toggleEdit1 = () => {
		setEditProfilePic(false);
	};

	const toggleEdit2 = () => {
		setEditCover(false);
	};

	const editUser = (val) => {
		console.log("Pressed button");
		setloading(true);
		let publicRecord = {
			displayName: `${val.firstName} ${val.lastName}`,
			firstName: val.firstName || data.firstName,
			lastName: val.lastName || data.lastName,
			email: val.email || data.email,
			summary: summary || "",
			details: details || "",
			primaryCategory: val.primaryCategory || "Coach",
			title: title || "Experienced Coach",
		};
		let privateRecord = {
			alternateEmail: val.alternateEmail || "-",
			mobile: val.mobile || "-",
			addressLine1: val.addressLine1 || "-",
			addressLine2: val.addressLine2 || "-",
			suburb: val.suburb || "-",
			city: val.city || "-",
			state: val.state || "-",
			postCode: val.postCode || "-",
			businessName: val.businessName || "-",
			abn: val.abn || "-",
			bankName: val.bankName || "-",
			bankAccountName: val.bankAccountName || "",
			bankAccountNumber: val.bankAccountNumber || "",
			bankBSBNumber: val.bankBSBNumber || "",
		};
		firestore()
			.collection("Coaches")
			.doc(data.key)
			.update(publicRecord)
			.then(() => {
				return firestore().collection("UserRecords").doc(data.key).update(privateRecord);
			})
			.then(() => {
				setUserInfo({ ...data, ...publicRecord });
				setPrivateData(privateRecord);
				setloading(false);
				toggleEdit();
			})
			.catch((err) => {
				setloading(false);
				message.error("Could not update record");
				console.log(err);
			});
	};

	return (
		<Badge.Ribbon
			text={(data.status || "Loading").toUpperCase()}
			placement="start"
			color={data.status === "active" ? colorsLight("green") : colorsLight("darkRed")}
		>
			<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
				<Row justify="end" align="top" style={imageBackground}>
					<Row
						onClick={() => setEditCover(true)}
						justify="center"
						align="middle"
						style={styles.editIconWrapper}
						data-testid="edit-icon"
					>
						<EditOutlined style={styles.editIcon} />
					</Row>
				</Row>
				<Row justify="space-between">
					<Row>
						<Row justify="center" align="middle" style={styles.imageWrapper}>
							<Avatar src={data.displayPic} size={130} icon={<UserOutlined />} />
							<Row
								justify="center"
								align="middle"
								onClick={() => setEditProfilePic(true)}
								onMouseEnter={() => setHoverProfilePic(true)}
								onMouseLeave={() => setHoverProfilePic(false)}
								style={{
									height: 130,
									width: 130,
									backgroundColor: hoverProfilePic
										? "rgba(0,0,0,0.5)"
										: "rgba(0,0,0,0)",
									position: "absolute",
									borderRadius: 65,
									cursor: "pointer",
								}}
								data-testid="edit-photo"
							>
								{hoverProfilePic && (
									<p style={{ color: "white", fontWeight: "bold", margin: 0 }}>
										Edit Photo
									</p>
								)}
							</Row>
						</Row>
						<Col style={{ paddingLeft: 12, marginTop: 6 }}>
							<Title style={{ margin: 0 }} level={1} data-testid="name">
								{data.displayName || "Loading"}
							</Title>
							{!editing ? (
								<Title
									style={{ margin: 0, fontWeight: 300, marginLeft: 2 }}
									level={4}
									data-testid="title"
								>
									{data.title || ""}
								</Title>
							) : (
								<Input
									style={{
										margin: 0,
										fontWeight: 300,
										marginLeft: 2,
										fontSize: 18,
										width: 300,
										marginTop: 6,
									}}
									placeholder="Please enter coach title"
									value={title}
									onChange={(val) => setTitle(val.target.value)}
									data-testid="title-edit"
								/>
							)}
						</Col>
					</Row>
					<Row style={{ padding: "12px 24px 0px" }}>
						{!editing ? (
							<Button onClick={toggleEdit} data-testid="edit">
								Edit Details
							</Button>
						) : (
							<>
								<Button onClick={toggleEdit} data-testid="cancel">
									Cancel
								</Button>
								<Button
									type="primary"
									form="myForm"
									loading={loading}
									key="submit"
									htmlType="submit"
									style={{ marginLeft: 6 }}
									data-testid="save"
								>
									Save Changes
								</Button>
							</>
						)}
					</Row>
				</Row>
				<Tabs type="card" style={{ marginLeft: 24, marginRight: 24, marginTop: 12 }}>
					<Tabs.TabPane key="1" tab="Information">
						{!editing ? (
							<AccountViewHeaderData data={data} privateData={privateData} />
						) : (
							<Form
								id="myForm"
								onFinish={editUser}
								initialValues={{ ...data, ...privateData }}
							>
								<Title level={5}>Basic Info:</Title>
								<Row style={styles.paddingHoro}>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">First Name:</p>
											<Form.Item
												style={styles.formField}
												name="firstName"
												rules={[
													{
														required: true,
														message: "Please enter user's first name",
													},
												]}
											>
												<Input
													placeholder="Please enter first name"
													data-testid="fname"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Last Name:</p>
											<Form.Item
												style={styles.formField}
												name="lastName"
												rules={[
													{
														required: true,
														message: "Please enter user's last name",
													},
												]}
											>
												<Input
													placeholder="Please enter last name"
													data-testid="lname"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Email:</p>
											<Form.Item
												style={styles.formField}
												name="email"
												rules={[
													{
														required: true,
														message: "Please enter user's email",
													},
												]}
											>
												<Input
													placeholder="Please enter email"
													data-testid="email"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Secondary Email:</p>
											<Form.Item
												style={styles.formField}
												name="alternateEmail"
												rules={[
													{
														required: false,
														message:
															"Please enter user's secondary email",
													},
												]}
											>
												<Input
													placeholder="Please enter second email"
													data-testid="email2"
												/>
											</Form.Item>
										</Row>
									</Col>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">Primary Category:</p>
											<Form.Item
												style={styles.formField}
												name="primaryCategory"
												rules={[
													{
														required: true,
														message: "Please select category",
													},
												]}
											>
												<Select data-testid="category">
													{coachCategories.map((cat, index) => (
														<Select.Option value={cat} key={index}>
															{cat}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Contact Number:</p>
											<Form.Item
												style={styles.formField}
												name="mobile"
												rules={[
													{
														required: false,
														message: "Please enter user's mobile",
													},
												]}
											>
												<Input
													placeholder="Please enter mobile"
													data-testid="mobile"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Member Since:</p>
											<p className="Value" data-testid="memberSince">
												{moment(data.created).format("Do MMMM YYYY")}
											</p>
										</Row>
									</Col>
								</Row>
								<Row style={styles.paddingHoro}>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
								</Row>
								<Title level={5}>Business Details:</Title>
								<Row style={styles.paddingHoro}>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">Business Name:</p>
											<Form.Item
												style={styles.formField}
												name="businessName"
												rules={[
													{
														required: false,
														message:
															"Please enter user's business name",
													},
												]}
											>
												<Input
													placeholder="Please enter business name"
													data-testid="businessName"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">ABN Number:</p>
											<Form.Item
												style={styles.formField}
												name="abn"
												rules={[
													{
														required: false,
														message: "Please enter user's abn",
													},
												]}
											>
												<Input
													placeholder="Please enter abn"
													data-testid="abn"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">City:</p>
											<Form.Item
												style={styles.formField}
												name="city"
												rules={[
													{
														required: false,
														message: "Please enter user's city",
													},
												]}
											>
												<Input
													placeholder="Please enter city"
													data-testid="city"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">State:</p>
											<Form.Item
												style={styles.formField}
												name="state"
												rules={[
													{
														required: false,
														message: "Please enter user's state",
													},
												]}
											>
												<Input
													placeholder="Please enter state"
													data-testid="state"
												/>
											</Form.Item>
										</Row>
									</Col>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">Address Line 1:</p>
											<Form.Item
												style={styles.formField}
												name="addressLine1"
												rules={[
													{
														required: false,
														message: "Please enter user's address",
													},
												]}
											>
												<Input
													placeholder="Please enter address"
													data-testid="address1"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Address Line 2:</p>
											<Form.Item
												style={styles.formField}
												name="addressLine2"
												rules={[
													{
														required: false,
														message: "Please enter user's address",
													},
												]}
											>
												<Input
													placeholder="Please enter address"
													data-testid="address2"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Suburb:</p>
											<Form.Item
												style={styles.formField}
												name="suburb"
												rules={[
													{
														required: false,
														message: "Please enter user's suburb",
													},
												]}
											>
												<Input
													placeholder="Please enter suburb"
													data-testid="suburb"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Postcode:</p>
											<Form.Item
												style={styles.formField}
												name="postCode"
												rules={[
													{
														required: false,
														message: "Please enter user's postcode",
													},
												]}
											>
												<Input
													placeholder="Please enter postcode"
													data-testid="postcode"
												/>
											</Form.Item>
										</Row>
									</Col>
								</Row>
								<Row style={styles.paddingHoro}>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
								</Row>
								<Title level={5}>Payment Details:</Title>
								<Row style={{ ...styles.paddingHoro, marginBottom: 24 }}>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">Bank Name:</p>
											<Form.Item
												style={styles.formField}
												name="bankName"
												rules={[
													{
														required: false,
														message: "Please enter user's bank name",
													},
												]}
											>
												<Input
													placeholder="Please enter bank name"
													data-testid="bank"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">Account Name:</p>
											<Form.Item
												style={styles.formField}
												name="bankAccountName"
												rules={[
													{
														required: false,
														message: "Please enter user's account name",
													},
												]}
											>
												<Input
													placeholder="Please enter account name"
													data-testid="accountName"
												/>
											</Form.Item>
										</Row>
									</Col>
									<Col
										style={{ paddingLeft: 12, paddingRight: 12 }}
										sm={12}
										xs={24}
									>
										<Row justify="space-between">
											<p className="Key">Account Number:</p>
											<Form.Item
												style={styles.formField}
												name="bankAccountNumber"
												rules={[
													{
														required: false,
														message:
															"Please enter user's account number",
													},
												]}
											>
												<Input
													placeholder="Please enter account number"
													data-testid="accountNumber"
												/>
											</Form.Item>
										</Row>
										<Row justify="space-between">
											<p className="Key">BSB Number:</p>
											<Form.Item
												style={styles.formField}
												name="bankBSBNumber"
												rules={[
													{
														required: false,
														message: "Please enter user's bsb number",
													},
												]}
											>
												<Input
													placeholder="Please enter bsb number"
													data-testid="bsb"
												/>
											</Form.Item>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Tabs.TabPane>
					<Tabs.TabPane key="2" tab="Your Profile">
						<Title level={5}>Summary:</Title>
						{!editing ? (
							<p style={{ minHeight: 60 }}>{data.summary}</p>
						) : (
							<TextArea
								style={{ marginBottom: 24, minHeight: 100 }}
								value={summary}
								onChange={(val) => setSummary(val.target.value)}
								data-testid="summary"
							/>
						)}
						<Title level={5}>Details:</Title>
						{!editing ? (
							<div
								style={{ minHeight: 100 }}
								dangerouslySetInnerHTML={{
									__html: data.details,
								}}
							/>
						) : (
							<ReactQuill
								style={{ marginBottom: 24 }}
								onChange={setDetails}
								value={details}
								placeholder="Write coach bio here..."
								theme="snow"
								data-testid="bio"
							/>
						)}
					</Tabs.TabPane>
				</Tabs>

				<ProfilePicUploadModal
					visible={editProfilePic}
					dismiss={toggleEdit1}
					data={data}
					setUserInfo={setUserInfo}
				/>
				<CoverPhotoUploadModal
					visible={editCover}
					dismiss={toggleEdit2}
					type="Coach"
					data={data}
					setUserInfo={setUserInfo}
				/>
			</Col>
		</Badge.Ribbon>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
	formField: {
		margin: "0px 0px 6px 0px",
	},
};

export default AccountViewHeader;
