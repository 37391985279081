import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserProvider } from "./Context/UserContext";
import { DataProvider } from "./Context/DataContext";
import { ViewProvider } from "./Context/ViewContext";
import Home from "./Pages/Home/HomePage";
import MySchedule from "./Pages/MySchedule/MySchedulePage";
import MyPackages from "./Pages/MyPackages/MyPackagesPage";
import ViewPackage from "./Pages/ViewPackage/ViewPackagePage";
import MyAccount from "./Pages/MyAccount/MyAccountPage";
import MyEarnings from "./Pages/MyEarnings/MyEarningsPage";
import Login from "./Pages/Authentication/login";
import PrivateRoute from "./Components/Navigation/PrivateRoute";
import "./Styles/main.scss";
import Activate from "./Pages/Authentication/activate";
import EnvChecker from "./Components/EnvChecker";

const App = () => {
	return (
		<ViewProvider>
			<UserProvider>
				<DataProvider>
					<EnvChecker />
					<Router>
						<Switch>
							<Route path="/activate/:params" component={Activate} />
							<Route path="/Login" component={Login} />

							<PrivateRoute path="/Home">
								{/* <Home /> */}
								<MyAccount />
							</PrivateRoute>
							<PrivateRoute path="/My-Packages">
								<MyPackages />
							</PrivateRoute>
							<PrivateRoute path="/View-Package/:packageId">
								<ViewPackage />
							</PrivateRoute>
							<PrivateRoute path="/My-Schedule">
								<MySchedule />
							</PrivateRoute>
							<PrivateRoute path="/My-Account">
								<MyAccount />
							</PrivateRoute>
							<PrivateRoute path="/My-Earnings">
								<MyEarnings />
							</PrivateRoute>
							<PrivateRoute path="/">
								{/* <Home /> */}
								<MyAccount />
							</PrivateRoute>
						</Switch>
					</Router>
				</DataProvider>
			</UserProvider>
		</ViewProvider>
	);
};

export default App;
