import {
	CrownOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PieChartOutlined,
	CalendarOutlined,
} from "@ant-design/icons";
import { Drawer, Menu, Row, Col, Layout } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo-small.png";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";
import MyAccountIcons from "../Account/MyAccountIcon";

const { Header } = Layout;
const variants = {
	open: { marginLeft: "150px", paddingRight: "150px" },
	closed: { marginLeft: "0px", paddingRight: "0px" },
};

const MobileMenu = ({ active }) => {
	const { drawerOpen, setDrawerOpen, setIsCollapsed, isLaptop, isIpad, windowWidth } = useContext(
		ViewContext
	);

	useEffect(() => {
		isLaptop || isIpad ? setIsCollapsed(true) : setIsCollapsed(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLaptop, isIpad]);

	return (
		<motion.div
			data-testid="menu-mobile"
			animate={drawerOpen ? "open" : "closed"}
			transition={{ duration: 0.25 }}
			variants={variants}
			style={{
				height: 64,
				width: windowWidth,
				minHeight: 64,
				backgroundColor: "white",
				display: "flex",
				flexDirection: "row",
				zIndex: 3,
				boxShadow: "1px 2px 7px rgba(0,0,0,0.1)",
				position: "fixed",
				paddingRight: 0,
			}}
		>
			<Header className="Header">
				<Row className="Fill" justify="space-between" align="middle" style={styles.topRow}>
					<Col
						onClick={() => setDrawerOpen(!drawerOpen)}
						justify="center"
						align="middle"
						style={{ height: "100%" }}
					>
						{drawerOpen ? (
							<MenuFoldOutlined style={{ fontSize: 22 }} data-testid="menu-close" />
						) : (
							<MenuUnfoldOutlined style={{ fontSize: 22 }} data-testid="menu-open" />
						)}
					</Col>
					<MyAccountIcons />
				</Row>
			</Header>
			<Drawer
				style={{ zIndex: 2, position: "fixed" }}
				visible={drawerOpen}
				placement="left"
				closable={false}
				bodyStyle={{ padding: 0, backgroundColor: "#001529" }}
				width={150}
				data-testid="menu-drawer"
			>
				<div
					style={{
						backgroundColor: colorsLight("primary"),
						height: 64,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<img
						src={Logo}
						alt="Careerbase logo"
						style={{ height: drawerOpen ? 18 : 22, alignSelf: "center" }}
						data-testid="menu-logo"
					/>
				</div>
				<Menu theme="dark" mode="inline" defaultSelectedKeys="1" selectedKeys={active}>
					<Menu.Item key="1" title="Home" icon={<PieChartOutlined />}>
						<Link
							to="/Home"
							data-testid="menu-home"
							onClick={() => setDrawerOpen(false)}
						>
							Home
						</Link>
					</Menu.Item>
					<Menu.Item key="2" title="My Packages" icon={<CrownOutlined />}>
						<Link
							to="/My-Packages"
							data-testid="menu-packages"
							onClick={() => setDrawerOpen(false)}
						>
							My Packages
						</Link>
					</Menu.Item>
					{/* <Menu.Item key="3" title="My Schedule" icon={<CalendarOutlined />}>
						<Link
							to="/My-Schedule"
							data-testid="menu-schedule"
							onClick={() => setDrawerOpen(false)}
						>
							My Schedule
						</Link>
					</Menu.Item> */}
				</Menu>
			</Drawer>
		</motion.div>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		paddingLeft: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default MobileMenu;
