import React, { useContext } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import { priceFormatter } from "../../Functions/formatters";
import UserContext from "../../Context/UserContext";

const PackagesTable = () => {
	const { packages } = useContext(UserContext);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Your Fee",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="package-price">
					{priceFormatter(text, "dollars")}
				</p>
			),
		},
		{
			title: "Orders",
			dataIndex: "orders",
			key: "orders",
		},
		{
			title: "Sessions",
			dataIndex: "sessions",
			key: "sessions",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="package-session">
					{text}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/View-Package/${record.key}`}>
					<Button size="small" data-testid="package-view">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Table
			style={{ width: "100%", overflow: "auto" }}
			columns={columns}
			dataSource={packages}
		/>
	);
};

export default PackagesTable;
