import React, { useContext } from "react";
import ViewContext from "../../Context/ViewContext";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const HeaderMain = ({ active }) => {
	const { isMobile } = useContext(ViewContext);
	return (
		<>
			{isMobile ? (
				<div>
					<MobileMenu active={active} />
				</div>
			) : (
				<DesktopMenu active={active} />
			)}
		</>
	);
};

export default HeaderMain;
