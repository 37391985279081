import React, { useState, useEffect } from "react";
import { message } from "antd";
import firebase from "../API/firebase";
import { auth, firestore } from "firebase";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [authd, setAuthd] = useState(true);
	const [uid, setUid] = useState("");
	const [userInfo, setUserInfo] = useState({});
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [balance, setBalance] = useState(0);
	const [packages, setPackages] = useState([]);
	const [calendarData] = useState([]);

	useEffect(() => {
		setLoading(true);
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setAuthd(true);
				setUid(user.uid);
			} else {
				setAuthd(false);
			}

			setLoading(false);
		});

		return;
	}, []);

	useEffect(() => {
		if (uid) {
			let listener = firestore()
				.collection("Coaches")
				.doc(uid)
				.onSnapshot((doc) => {
					if (doc.exists) {
						setUserInfo({ ...doc.data(), key: doc.id });
					}
				});
			return () => {
				listener();
			};
		}
	}, [uid]);

	useEffect(() => {
		if (uid) {
			let listener = firestore()
				.collection("Coaches")
				.doc(uid)
				.collection("Packages")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setPackages(list);
				});
			return () => {
				listener();
			};
		}
	}, [uid]);

	useEffect(() => {
		if (uid) {
			const transactionsRef = firebase.database().ref(`Ledger/Accounts/${uid}/transactions`);
			const balanceRef = firebase.database().ref(`Ledger/Accounts/${uid}/balance`);

			transactionsRef.on("value", (snapshot) => {
				const dat = snapshot.val();
				if (dat) {
					let keys = Object.keys(dat);
					let list = [];
					keys.forEach((item) => {
						list.push({ ...dat[item], key: item });
					});
					const sorted = list.sort((a, b) => new Date(b.created) - new Date(a.created));
					setTransactions(sorted);
				}
			});

			balanceRef.on("value", (snapshot) => {
				const data = snapshot.val();
				setBalance(data);
			});

			return () => {
				transactionsRef.off();
				balanceRef.off();
			};
		}
	}, [uid]);

	return (
		<UserContext.Provider
			value={{
				authd,
				setAuthd,
				uid,
				setUid,
				userInfo,
				setUserInfo,
				loading,
				transactions,
				packages,
				balance,
				calendarData,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
