import { Row, Col, Card } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import { amountFormatter, priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const MyAccountBalancePanel = () => {
	const { transactions, balance } = useContext(UserContext);

	return (
		<Card style={{ borderRadius: 6, paddingBottom: 0 }} title="My Earnings">
			<Col>
				<Row>
					<p style={{ margin: 0, fontSize: 13 }}>Current Balance:</p>
				</Row>
				<Row>
					<p style={styles.balance} data-testid="account-balance">
						{priceFormatter(balance, "points") + " pts"}
					</p>
				</Row>
				{transactions.length > 0 ? (
					<>
						<Row style={{ marginTop: 24 }}>
							<p style={{ margin: 0, fontSize: 13 }}>Recent Earnings:</p>
						</Row>
						{transactions.slice(0, 2).map((item, index) => (
							<Row
								key={index}
								justify="space-between"
								align="middle"
								style={styles.transactionWrapper}
								data-testid="account-activity"
							>
								<Col span={16} style={styles.description}>
									{item.description}
								</Col>
								<Col span={8} style={styles.credit}>
									{amountFormatter(item.amount, "credit")}
								</Col>
							</Row>
						))}
					</>
				) : (
					<Row justify="center" align="middle">
						No Recent Earnings
					</Row>
				)}
				{transactions.length > 2 && (
					<Row style={{ width: "100%", paddingTop: 12 }} justify="center" align="middle">
						<Link style={{ margin: 0 }} to="/My-Earnings" data-testid="account-button">
							View More
						</Link>
					</Row>
				)}
			</Col>
		</Card>
	);
};

const styles = {
	balance: {
		fontSize: 32,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	transactionWrapper: {
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 6,
		paddingRight: 6,
		marginTop: 9,
		// height: 30,
	},
	description: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
	},
	credit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("green"),
		textAlign: "end",
	},
};

export default MyAccountBalancePanel;
