import React, { useContext } from "react";
import { Row, Col, Layout, Typography } from "antd";
import MenuMain from "../../Components/Menu/MenuMain";
import { colorsLight } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import ViewContext from "../../Context/ViewContext";
import EarningsTable from "../../Components/MyEarnings/EarningsTable";

const { Header, Content } = Layout;

const Packages = () => {
	const { isMobile } = useContext(ViewContext);

	return (
		<Layout className="App">
			<MenuMain active={["6"]} />
			<Layout>
				{!isMobile && (
					<Header className="Header">
						<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
							<MyAccountIcons />
						</Row>
					</Header>
				)}
				<Content
					style={{
						overflow: "scroll",
						padding: isMobile ? 12 : 24,
						paddingBottom: isMobile ? 42 : 24,
						paddingTop: isMobile ? 83 : 24,
					}}
					className="content"
				>
					<Col style={{ backgroundColor: colorsLight("lightgrey"), height: "100%" }}>
						<Typography.Title>My Earnings</Typography.Title>
						<EarningsTable />
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default Packages;
