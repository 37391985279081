import React from "react";
import { Row, Col, Divider, Typography, InputNumber } from "antd";
import { priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
const { Title } = Typography;

const PackageViewCommercials = ({ data, costPrice, setCostPrice, editing, salePrice, profit }) => {
	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Commercials</Title>
			</Row>
			<Row justify="space-between" align="middle" style={styles.row}>
				<Col className="Key">Sale Price:</Col>
				{salePrice && (
					<Col style={styles.cost} data-testid="cost">
						{priceFormatter(salePrice, "dollars")}
					</Col>
				)}
			</Row>
			<Row
				justify="space-between"
				align="middle"
				style={{ ...styles.row, marginBottom: editing ? 12 : 0 }}
			>
				<Col className="Key">Your Fee:</Col>
				{data.costPrice && !editing && (
					<Col style={styles.cost} data-testid="price">
						{priceFormatter(data.costPrice, "dollars")}
					</Col>
				)}
				{editing && (
					<InputNumber
						size="large"
						style={{ ...styles.cost, width: 110 }}
						value={costPrice}
						formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						onChange={(val) => setCostPrice("costPrice", val)}
						data-testid="price-edit"
					/>
				)}
			</Row>
			<Divider style={{ margin: 0 }} />
			<Row justify="space-between" align="middle" style={styles.row}>
				<Col className="Key">Profit:</Col>
				{data.costPrice && (
					<Col style={styles.profit} data-testid="profit">
						{priceFormatter(profit, "dollars")}
					</Col>
				)}
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
		minHeight: 30,
	},
	profit: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	cost: {
		fontSize: 18,
		fontWeight: "bold",
		margin: 0,
	},
};

export default PackageViewCommercials;
