import { randomColour } from "./helpers";

export const getInitials = (name = "") => {
	const parts = name.split(" ");
	let initials = "";
	for (var i = 0; i < parts.length; i++) {
		if (parts[i].length > 0 && parts[i] !== "") {
			initials += parts[i][0];
		}
	}
	return initials;
};

export const coachTagFormatter = (type) => {
	switch (type) {
		case "Outplacement":
			return "magenta";
		case "Executive Coach":
			return "cyan";
		case "Fitness":
			return "purple";
		default:
			return randomColour();
	}
};

export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const amountFormatter = (num, type) => {
	if (type === "credit") {
		const cleaned = num;
		return `+${cleaned.toLocaleString()} pts`;
	} else if (type === "debit") {
		const cleaned = num;
		return `-${cleaned.toLocaleString()} pts`;
	} else {
		return "error";
	}
};

export const priceFormatter = (num = "", type) => {
	if (type === "dollars") {
		const res = num.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
		return res;
	} else {
		const converted = num * 3.142;
		const res = Number(converted.toFixed(0)).toLocaleString();
		return res;
	}
};
