import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Calendar, Badge } from "antd";
import moment from "moment";
import { randomColour } from "../../Functions/helpers";
import ViewContext from "../../Context/ViewContext";
import ScheduleItem from "./ScheduleItem";

const calData = {
	20210130: [
		{
			title: "Coaching 1",
			startTime: "2021-01-30T09:30:00+08:00",
			endTime: "2021-01-30T11:30:00+08:00",
		},
		{
			title: "Coaching 2",
			startTime: "2021-01-30T14:00:00+08:00",
			endTime: "2021-01-30T14:30:00+08:00",
		},
		{
			title: "Coaching 3",
			startTime: "2021-01-30T16:00:00+08:00",
			endTime: "2021-01-30T17:00:00+08:00",
		},
	],
};

const ScheduleCalendar = () => {
	const { isMobile } = useContext(ViewContext);
	const [activeDate, setActiveDate] = useState(new Date());
	const [schedule, setSchedule] = useState([]);

	const dateChange = (time) => {
		setActiveDate(time.toDate());
	};

	useEffect(() => {
		if (calData) {
			const fullDate = moment.utc(activeDate).format("YYYYMMDD");
			const keys = Object.keys(calData);
			let events = [];
			keys.forEach((key) => {
				let sub = key.substring(0, 8);
				if (sub === fullDate) {
					events = calData[key];
				}
			});
			setSchedule(events);
		} else {
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDate]);

	const cellrend = (value) => {
		const fullDate = moment(value).format("YYYYMMDD");
		let events = [];
		if (calData) {
			let keys = Object.keys(calData);
			keys.forEach((key) => {
				let sub = key.substring(0, 8);
				if (sub === fullDate) {
					calData[key].forEach((event) => {
						events.push({
							title: event.title,
							startTime: event.startTime,
							endTime: event.endTime,
						});
					});
				}
			});
		}
		return (
			<div>
				{events.map((item, index) => (
					<>
						{isMobile ? (
							<Badge
								key={index}
								color={randomColour()}
								data-testid="schedule-badge"
							/>
						) : (
							<div
								key={index}
								style={{
									minHeight: 20,
									backgroundColor: randomColour(),
									width: "100%",
									borderRadius: 5,
								}}
							>
								<p
									style={{
										color: "white",
										fontSize: 12,
										marginLeft: 3,
										marginBottom: 0,
										marginTop: 2,
									}}
									data-testid="schedule-details"
								>
									{moment(item.startTime).format("h:mm a")}-
									{moment(item.endTime).format("h:mm a")} <b>{item.title}</b>
								</p>
							</div>
						)}
					</>
				))}
			</div>
		);
	};

	return (
		<>
			{isMobile && (
				<Col data-testid="schedule-list">
					<div style={{ marginBottom: 24 }}>
						<Row style={{ margin: 12 }}>
							<Col>
								<p
									style={{
										fontSize: 24,
										marginBottom: 0,
										fontWeight: "bold",
									}}
									data-testid="schedule-date"
								>
									{moment(activeDate).format("dddd, D MMMM YYYY")}
								</p>
							</Col>
						</Row>
						<Row style={{ marginBottom: 24 }}>
							{schedule.length > 0 ? (
								<>
									{schedule.map((item, index) => (
										<ScheduleItem data={item} key={index} />
									))}
								</>
							) : (
								<div
									style={{
										width: "100%",
										justifyContent: "center",
										display: "flex",
									}}
								>
									<p data-testid="schedule-nothing">Nothing happening today...</p>
								</div>
							)}
						</Row>
					</div>
				</Col>
			)}
			<Col className="Card" data-testid="schedule-calendar">
				<Calendar
					dateCellRender={cellrend}
					onChange={(time) => dateChange(time)}
					fullscreen={!isMobile}
				/>
			</Col>
		</>
	);
};

export default ScheduleCalendar;
