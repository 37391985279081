import { Button, Col, Row } from "antd";
import React from "react";
import moment from "moment";
import { colorsLight } from "../../Styles/Themes";

const StepComponent = ({ data, handleOnClick }) => {
	const now = moment().format("YYYYMMDDHHmm");
	const eventTime = moment(data.endTime).format("YYYYMMDDHHmm");

	return (
		<>
			{/* RENDERS IF EVENT WAS IN THE PAST */}
			{now > eventTime && data.startTime !== "TBA" && (
				<Col>
					<Row>
						<p style={styles.date}>
							{moment(data.startTime).format("h:mm a dddd | MMM Do YYYY")}
						</p>
					</Row>
					<Row>
						<p style={styles.title}>{data.title}</p>
					</Row>
					<Row>
						<p>Status:</p>
						<p style={styles.completed}>Completed</p>
					</Row>
				</Col>
			)}

			{/* RENDERS IF DATE HAS NOT BEEN PICKED AND EVENT IS IN THE FUTURE */}
			{data.startTime === "TBA" && (
				<Col>
					<Row>
						<p style={styles.date}>To be decided</p>
					</Row>
					<Row>
						<p style={styles.title}>{data.title}</p>
					</Row>
					<Row justify="space-between">
						<Row>
							<p>Status:</p>
							<p style={styles.unbooked}>Awaiting booking</p>
						</Row>
						<Button
							onClick={() => handleOnClick && handleOnClick()}
							data-testid="book-session"
						>
							Book
						</Button>
					</Row>
				</Col>
			)}

			{/* RENDERS IF DATE HAS BEEN SELECTED */}
			{data.startTime !== "TBA" && now < eventTime && (
				<Col>
					<Row>
						<p style={styles.date}>
							{moment(data.startTime).format("h:mm a dddd | MMM Do YYYY")}
						</p>
					</Row>
					<Row>
						<p style={styles.title}>{data.title}</p>
					</Row>
					<Row justify="space-between">
						<Row>
							<p>Status:</p>
							<p style={styles.booked}>Confirmed</p>
						</Row>
						<Button
							style={styles.button}
							type="link"
							onClick={() => handleOnClick && handleOnClick()}
							data-testid="edit-session"
						>
							Edit
						</Button>
					</Row>
				</Col>
			)}
			{/* <SchedulingController coachData={coachData} /> */}
		</>
	);
};

const styles = {
	wrapper: {
		width: "100%",
	},
	booked: {
		fontSize: 14,
		color: "#2dc460",
		marginLeft: 6,
		fontWeight: "bold",
	},
	unbooked: {
		fontSize: 14,
		color: "#e0b91b",
		marginLeft: 6,
		fontWeight: "bold",
	},
	date: {
		fontSize: 14,
		margin: 0,
		color: colorsLight("grey"),
	},
	completed: {
		fontSize: 14,
		color: "#2dc460",
		marginLeft: 6,
		fontWeight: "bold",
	},
	title: {
		margin: 0,
		fontSize: 16,
		color: colorsLight("primary"),
		fontWeight: "bold",
	},
	button: {
		lineHeight: 1,
		height: "fit-content",
	},
};

export default StepComponent;
