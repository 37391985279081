import React, { useState } from "react";

const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
	const [coaches, setCoaches] = useState([]);

	return (
		<DataContext.Provider
			value={{
				coaches,
				setCoaches,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export default DataContext;
