export const iDgen = (length) => {
	var result = "";
	var characters = "abcdefghijklmnopqrstuv0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getImage = () => {
	let num = Math.floor(Math.random() * 2);
	let images = [
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault1.jpg?alt=media&token=28a1fbc4-00e5-4ed3-9c75-de577aa65662",
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault2.jpg?alt=media&token=ca80f5c7-515f-4bbc-9f42-e166281a433a",
	];
	return images[num];
};
